<template>
    <div>
        <LoaderDefault v-if="isLoading" />
        <div v-else>
            <div class="page-header back-image2"></div>
            <div class="col-md-1">
                <div class="wrapper-content bg-white pinside40">
                    <div class="container-transparencia">
                        <a-col class="a-col-mb">
                            <p class="tex-mp">
                                A través de este medio los usuarios podrán dejar su petición, queja y/o reclamo
                                que tengan para la

                                Empresa de Acueducto y Alcantarillado de Silvia , el mensaje será enviado a la

                                Dependencia encargada de atenderlo y tan pronto como sea posible el Jefe de ésta
                                le respondera al
                                correo electrónico y/o número teléfonico que registro
                            </p>
                            <h3 class="tex-h3">¿Qué es una petición?</h3>
                            <p class="tex-mp">Es el derecho que constitucionalmente tiene todo ciudadano para presentar y/o
                                solicitar, respetuosamente, una petición generada por motivos que son de interés
                                general o particular.</p>

                            <h3 class="tex-h3">¿Qué es una queja?</h3>
                            <p class="tex-mp">Es la manifestación que se genera por la insatisfacción de un usuario con
                                respecto al comportamiento de un funcionario de la Empresa.</p>

                            <h3 class="tex-h3">¿Qué es un reclamo?</h3>
                            <p class="tex-mp">Es la insatisfacción que el usuario puede presentar por el no cumplimiento o
                                irregularidad en alguno de los trámites y/o servicios ofrecidos por la Empresa.
                            </p>

                            <h3 class="tex-h3">¿Qué es una sugerencia?</h3>
                            <p class="tex-mp">Es una propuesta que expresa un usuario en procura del mejoramiento de los
                                servicios que ofrece la Empresa.</p>
                        </a-col>

                        <a-col class="a-col-mb">
                            <h1 class="titulo-pqrs">Formulario de PQRS</h1>
                        </a-col>

                        <div novalidate autocomplete="off" class="form-recurso ng-invalid ng-touched ng-dirty">
                            <div class="epm-container">
                                <div class="epm-container_section epm-container_section--shadow">
                                    <a-col class="epm-floating-group epm-container-group">
                                        <h1 appspeak class="epm-title">Datos de contacto</h1>
                                    </a-col>
                                    <div novalidate autocomplete="off" class="form-recurso ng-invalid ng-touched ng-dirty">
                                        <div class="epm-container-row">
                                            <div class="epm-floating-group epm-container-group">
                                                <select v-model="dataPqr.id_type" name="id_type" id="id_type"
                                                    class="select input-anexo epm-floating_field epm-floating--line ng-touched ng-dirty ng-invalid"
                                                    required>
                                                    <option selected="true" value>Seleccione una opcion
                                                    </option>
                                                    <option value="cedula">Cédula</option>
                                                    <option value="cedula de otro pais "> Cédula de otro
                                                        país </option>
                                                    <option value="cedula Extranjera"> Cédula Extranjería
                                                    </option>
                                                    <option value="Pasaporte"> Pasaporte </option>
                                                </select>
                                                <label for="id_type" class="label asterisco epm-floating_label">Tipo de
                                                    identificacion *
                                                </label>
                                            </div>
                                            <div class="epm-floating-group epm-container-group">
                                                <input v-model="dataPqr.identification_number" name="identification_number"
                                                    id="identification_number" type="text"
                                                    class="input-anexo epm-floating_field epm-floating--line is-invalid ng-untouched ng-pristine ng-invalid"
                                                    required>

                                                <label for="identification_number"
                                                    class="label asterisco epm-floating_label">Numero de
                                                    identificacion *</label>

                                            </div>
                                        </div>
                                        <div class="epm-container-row">
                                            <div class="epm-floating-group epm-container-group">

                                                <input v-model="dataPqr.names" name="names" type="text" id="names"
                                                    class="input-anexo epm-floating_field epm-floating--line ng-pristine ng-invalid ng-touched"
                                                    required>

                                                <label for="names" class="label asterisco epm-floating_label">Nombres
                                                    *</label>

                                            </div>
                                            <div class="epm-floating-group epm-container-group">

                                                <input v-model="dataPqr.surnames" name="surnames" type="text" id="surnames"
                                                    class="input-anexo epm-floating_field epm-floating--line ng-untouched ng-pristine ng-invalid"
                                                    required>
                                                <label for="surnames" class="label asterisco epm-floating_label">Apellidos
                                                    *</label>
                                            </div>
                                        </div>
                                        <div class="epm-container-row">

                                            <div class="epm-floating-group epm-container-group">
                                                <input v-model="dataPqr.email" name="email" id="email" type="email"
                                                    class="input-anexo epm-floating_field epm-floating--line ng-pristine ng-invalid ng-touched"
                                                    required>
                                                <label for="email" class="label asterisco epm-floating_label">Correo
                                                    electronico*</label>

                                            </div>

                                            <div class="epm-floating-group epm-container-group">
                                                <input v-model="dataPqr.Confirm_Email" name="Confirm_Email"
                                                    id="Confirm_Email" type="email"
                                                    class="input-anexo epm-floating_field epm-floating--line ng-pristine ng-invalid ng-touched"
                                                    required>
                                                <label for="Confirm_Email"
                                                    class="label asterisco epm-floating_label">confirmar
                                                    correo *</label>

                                            </div>

                                        </div>
                                        <div class="epm-container-row">

                                            <div class="epm-floating-group epm-container-group">
                                                <input v-model="dataPqr.landline" name="landline" type="text" id="landline"
                                                    class="input-anexo epm-floating_field epm-floating--line ng-untouched ng-pristine ng-valid"
                                                    maxlength="7" minlength="7" required>
                                                <label for="landline" class="label epm-floating_label">Telefono fijo
                                                    *</label>

                                            </div>

                                            <div class="epm-floating-group epm-container-group">
                                                <input v-model="dataPqr.cell_phone" name="cell_phone" type="text"
                                                    id="cell_phone"
                                                    class="input-anexo epm-floating_field epm-floating--line ng-untouched ng-pristine ng-valid"
                                                    maxlength="10" minlength="10" required>
                                                <label for="cell_phone" class="label epm-floating_label">Telefono
                                                    Celular *</label>
                                            </div>
                                        </div>
                                        <div class="epm-container-row">
                                            <div class="epm-floating-group epm-container-group">
                                                <select v-model="dataPqr.select_option" name="select_option"
                                                    id="select_option"
                                                    class="select input-anexo epm-floating_field epm-floating--line ng-touched ng-dirty ng-invalid"
                                                    required>
                                                    <option value="Peticion">Peticion</option>
                                                    <option value="Queja">Queja</option>
                                                    <option value="Reclamo">Reclamo</option>
                                                    <option value="Sugerencia">Sugerencia</option>
                                                </select>

                                                <label for="select_option"
                                                    class="label asterisco epm-floating_label">Selecciona
                                                    una opcion *
                                                </label>
                                            </div>
                                            <div class="epm-floating-group epm-container-group">

                                                <select v-model="dataPqr.select_service" name="select_service"
                                                    id="select_service"
                                                    class="select input-anexo epm-floating_field epm-floating--line ng-touched ng-dirty ng-invalid"
                                                    required>
                                                    <option value="Acueducto">Acueducto</option>
                                                    <option value="Alcantarillado">Alcantarillado</option>
                                                    <option value="Aseo">Aseo</option>
                                                </select>

                                                <label for="select_service"
                                                    class=" label asterisco epm-floating_label">Selecciona
                                                    un Servicio *
                                                </label>
                                            </div>
                                        </div>
                                        <div class="epm-container-row">
                                            <div class="epm-floating-group epm-container-group">
                                                <textarea v-model="dataPqr.description" name="description" type="text"
                                                    id="description" class="input-anexo descripcion-texto"
                                                    placeholder="Escribe aquí tu descripción" required></textarea>

                                                <label for="description"
                                                    class="label asterisco epm-floating_label">Descripcion
                                                    *</label>
                                            </div>
                                        </div>

                                        <h2 class="politica-notificaciones">Política de Tratamiento de Datos Personales y
                                            Notificaciones</h2>
                                            <li class="text-li" >
                                                <a class="tex-a" target="”_blank”" href="https://firebasestorage.googleapis.com/v0/b/emsilvia-ded00.appspot.com/o/politicaDatos%20personales%2FPOLITICA%20DE%20TRATAMIENTO%20DE%20DATOS%20EMSILVIA%20ESP.pdf?alt=media&token=b15d0105-b9a8-45ff-a854-3cef167c0801">Política de tratamiento de datos personales</a>
                                            </li>
                                        <label for="notification_policy" class="politica-notificaciones-label">Al presentar
                                            esta
                                            PQR por este medio, acepto y autorizo a EMSILVIA E.S.P. Para que todas las
                                            notificaciones me
                                            sean enviadas al correo electrónico registrado, esto con base en el artículo 56
                                            de
                                            la Ley 1437 de 2011.*</label>

                                        <!-- Acepto la política y que las notificaciones me sean enviadas al correo electrónico registrado -->
                                        <div class="tex-privacidad">
                                            <input type="checkbox" v-model="something" id="accept_policy"
                                                class="politica-notificaciones-checkbox" required>
                                            <label for="accept_policy" class="politica-notificaciones-label">Acepto la
                                                política y que las notificaciones me sean enviadas al correo electrónico
                                                registrado</label>
                                        </div>

                                        <div class="epm-btn-group">
                                            <button type="button" data-dismiss="modal" appspeak
                                                class="epm-btn epm-btn--secondary-line">Volver</button>
                                            <button @click="storeInfoDateBase()" class="epm-btn epm-btn--primary">
                                                <span appspeak>Enviar</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { db } from '../../../../firebaseConfig'
import LoaderDefault from '../../../components/Loaders/LoaderDefault.vue';
export default {
    components: {
        LoaderDefault
    },
    data() {
        return {
            isLoading: false,
            dataPqr: {
                _id: "id",
                id_type: "",
                identification_number: "",
                names: "",
                surnames: "",
                email: "",
                Confirm_Email: "",
                landline: "",
                cell_phone: "",
                select_option: "",
                select_service: "",
                description: ""
            },
            something: false
        }
    },
    mounted() {

    },

    methods: {
        async storeInfoDateBase() {
            if (this.validaFormPqrs()) {
                if (this.something) {
                    try {
                        this.isLoading = true
                        const collectionRef = db.collection('infoPqr');
                        const docRef = await collectionRef.add(this.dataPqr);
                        const docId = docRef.id;
                        await collectionRef.doc(docId).update({ _id: docId });
                        this.$swal.fire({
                            title: 'Se ha registrado su PQR con éxito',
                            text: 'Hemos recibido tu consulta y nos pondremos en contacto contigo lo antes posible. Valoramos tu opinión y nos esforzamos por brindarte el mejor servicio. Si tienes alguna pregunta adicional, no dudes en contactarnos.¡Gracias nuevamente y que tengas un excelente día!',
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonText: 'Aceptar',
                            confirmButtonColor: '#45A825',
                        });
                        this.dataPqr = {};
                        this.isLoading = false
                    } catch (error) {
                        this.isLoading = false
                    }
                } else {
                    this.error()
                }
            }
        },
        error() {
            this.$error({
                title: '¡Importante!',
                content: 'Antes de continuar, es necesario que aceptes nuestras políticas de privacidad. Queremos asegurarnos de proteger tus datos y brindarte una experiencia segura en nuestra página web. Por favor, lee detenidamente nuestras políticas y marca la casilla de aceptación para continuar navegando. ¡Gracias por tu comprensión y colaboración!',
            });
        },

        validaFormPqrs() {
            const inputs = document.querySelectorAll('.input-anexo');
            const labels = document.querySelectorAll('.label');
            for (let i = 0; i < inputs.length; i++) {
                if (inputs[i].hasAttribute('required') && inputs[i].value.trim() === '') {
                    const label = document.querySelector(`label[for="${inputs[i].id}"]`);
                    label.style.color = 'red';
                    label.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    return false;
                } else {
                    inputs[i].style.backgroundColor = '';
                    labels[i].style.color = '';
                }
            }
            return true;
        }


    },

}
</script>
<style lang="css">
.back-image2 {
    background: url('../../../assets/img/fotoPqr.png') no-repeat center;
    background-size: cover;


}


.titulo-pqrs {
    font-size: 32px;
    line-height: 44px;
    letter-spacing: -1px;
    color: var(--colorGreenPrimary);
    font-weight: 900;
    margin: 0px 0px 10px 0px;
    text-align: left;
}

@media(max-width:300px) {
    .titulo-pqrs {
        font-size: 14vw;
        line-height: 30px;
    }
}


/*  formulario nuevo*/

@media (max-width: 500px) {
    .epm-container {
        max-width: 100%;


    }
}

.epm-container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.epm-container_section {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, .25);
    padding: 20px;
    border-radius: 20px;
    background-color: #fff;
}

@media(max-width:400px) {
    .epm-container_section {

        margin: 0px;
        padding: 0px;
    }
}

.epm-container_section--shadow {

    border: none;
}

.epm-floating-group {

    position: relative;
    margin: 30px 10px;

}

@media(max-width:400px) {
    .epm-floating-group {
        margin: 0px;
        margin-bottom: 10px;
    }
}



.epm-container-group {

    padding-right: 5px;
    padding-left: 5px;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    min-width: 200px;
}

.epm-title {
    font-size: 20px;
    color: #3c4d6b;
    ;
    text-align: left;
}




.epm-container-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
    margin-bottom: 10px;
}

@media(max-width:400px) {
    .epm-container-row {
        margin: 0px;
    }
}



.epm-floating--line {
    transition: border-color .25s ease-in-out;
    color: #707070;
    border-bottom: 2px solid #b9bbbb;
    background-color: transparent;
    box-sizing: border-box;
    padding-left: 14px;
    padding-top: 14px;
    padding: 10px;
}

.epm-floating_field {
    position: relative;
    border-width: 1;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    z-index: 2;
    font: inherit;
    line-height: 1;
    display: block;
    width: 100%;
    outline: none;
}

select {

    border: none;
    appearance: none;
    background: #fff no-repeat calc(100% - 10px) !important;
    text-transform: none;
    margin: 0;
}

.epm-floating--line:not(:placeholder-shown)+.epm-floating_label {
    transform: translate(0.25em, -32%) scale(.8);
}

.epm-floating--line+.epm-floating_label {
    width: auto;
    transition: opacity .25s ease-in-out;
    transform-origin: 0 0;
    padding: 10px;
    z-index: 100;
}

@media(max-width:400px) {
    .epm-floating--line+.epm-floating_label {
        padding: 0px;
    }
}

.epm-container-group label {
    display: block;
    color: #8b8b8b;
    margin-bottom: 8px;
    font-size: 15px;
}

.epm-floating_label {

    position: absolute;
    top: 0;
    left: 0;
    user-select: none;
    font: inherit;
    line-height: 1;
}

input {
    border: none;
    overflow: visible;
    margin: 0;
}





.epm-btn {

    padding: 0 20px;
    text-decoration: none;
    cursor: pointer;
}

.epm-btn--secondary-line {
    background-color: #fff;
    border-color: var(--colorGreenPrimary);
    color: var(--colorGreenPrimary);
}

.epm-btn {

    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    border-radius: 25px;
    box-sizing: border-box;
    transition: .6s;
    line-height: 1;
    font-size: 1em;
    margin: 5px;
    border: 2px solid;
}

.epm-btn--secondary-line:hover {
    background-color: var(--colorGreenPrimary);
    border-color: var(--colorGreenPrimary);
    color: #fff;
}

.epm-btn--primary:hover {
    background-color: var(--colorGreenPrimary);
    border-color: var(--colorGreenPrimary);
    color: #fff;
}

.epm-btn-group .epm-btn:last-child {
    margin-right: 0;
}

.epm-btn--primary {

    background-color: var(--colorGreenSecundary);
    border-color: var(--colorGreenSecundary);
    color: #fff;
}

.descripcion-texto {
    width: 75%;
    height: 150px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 8px;
    float: left;
    resize: none;
    margin-top: 30px;
    outline: none;

}

/* politica datos */
.politica-notificaciones {
    margin-top: 20px;
    font-weight: 700;
    font-size: 1.25em;
    margin-bottom: 20px;
    color: #3c4d6b;
    border-bottom: 1px solid #CCC;

}

.politica-notificaciones-label {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-left: 5px;
    margin-bottom: 20px;
    font-weight: 700;
    font-size: inherit;

}

.politica-notificaciones-checkbox {
    margin-top: 10px;
    margin-bottom: 20px;

}

.tex-privacidad {
    display: flex;
    text-align: center;
    margin-bottom: 20px;
}
.text-li{
    margin-bottom: 20px;
    color: #67aed4;
}
</style>